
<template>
  <div>
    <v-container class="text-center mt-10">
      <h1 class="blue--text text--darken-3">Verifikasi dan Aktivasi Akun</h1>
      <p class="mt-10">Permintahan Login Sudah dikirim kepada Admin.</p>
      <p>Silahkan tunggu untuk mendapatkan Whatsapp Notifikasi</p>
      <p class="font-italic">Pastikan Nomor Whatsapp Anda Benar</p>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'VerifikasiLoginLinkSendView',
  components: {},
  data() {
    return {

    }
  },
  methods: {
    submit() {
      this.$router.push('/home');
    }
  }
}
</script>

<style scoped>

</style>